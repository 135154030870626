class Autologin {
  constructor(target) {
    this.autologin = document.getElementById(target);
    this.serverTimeValue = this.autologin.getAttribute('data-autologin-server-time-value');
    this.signInButton = this.autologin.querySelector('[data-autologin-target="button"]');
  }

  checkForAutoLogin() {
    let sessionTimeout = this.getCookie("session_expires_at");
    if (sessionTimeout) {
      if (parseInt(this.serverTimeValue) < parseInt(sessionTimeout) && this.getCookie("autologin_attempted") != "true") {
        this.setCookie("autologin_attempted", "true");
        this.signInButton.click();
      }
    }
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(name, value) {
    document.cookie = name + "=" + value + ";path=/";
  }
}

window.Autologin = Autologin;
